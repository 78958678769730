@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e4eef6;
}

code {
  font-family: 'Mulish', sans-serif;
}

#map {
  height: 85vh;
}

#map-product {
  height: 324px;
}
